'use client'

import { likeAndFollow } from '@/backend/apis/like-and-follow/LikeAndFollowAPI'
import { useMutation } from '@tanstack/react-query'
import React from 'react'

export function LikeButton({
  id,
  className = '',
  children,
  type = 'character',
}: {
  id: string
  className: string
  children: React.ReactNode
  type?: 'character' | 'user' | 'collabo'
}) {
  // FIXME 일단 type이 'collabo'일 경우 'character'로 변경했습니다! 추후 실제 COLLABO LIKE API로 교체 필요합니다!
  const mutation = useMutation({
    mutationFn: async (characterId: string) => {
      await likeAndFollow({
        id: characterId,
        type: type,
      })
    },
  })

  return (
    <button
      disabled={mutation.isPending}
      onClick={async (e) => {
        e.preventDefault()
        mutation.mutate(id)
      }}
      type="button"
      className={className}>
      {children}
    </button>
  )
}
