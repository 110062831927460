'use client'

import AgencyVerified from '@/app/_components/Icon/AgencyVerified'
import CheckAll from '@/app/_components/Icon/CheckAll'
import Verified from '@/app/_components/Icon/Verified'
import Tooltip from '@/app/_components/Shared/Tooltip'
import { cn } from '@/app/utils'
import { getUserAvatarURL } from '@/libraries/avatar'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import China from '../china.svg'
import ETC from '../etc.svg'
import EU from '../eu.svg'
import Japan from '../japanese.svg'
import Korea from '../korean.svg'
import USA from '../usa.svg'

type userType = {
  id: string
  type: string
  displayName: string | null
  imageURL: string | null
  isVerified?: boolean
  isAgency?: boolean
  country?: string
}

const getCountryImageURL = (country?: string) => {
  if (country === 'kr') return Korea
  else if (country === 'jp') return Japan
  else if (country === 'cn') return China
  else if (country === 'us') return USA
  else if (country === 'eu') return EU
  else return ETC
}

export function Avatar({
  userInfo,
  size = 'small',
  className,
  href,
}: {
  userInfo: userType
  size?: 'small' | 'medium'
  className?: string
  href: string
}) {
  const isMedium = Boolean(size === 'medium')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Link
      href={href}
      className={cn(
        'relative c-b4 flex items-center gap-8 text-[#7C7C7C]',
        className,
      )}>
      <div
        className={cn(
          'relative size-32 shrink-0 overflow-hidden rounded-full',
          { 'size-40': isMedium },
        )}>
        <Image
          src={
            userInfo.imageURL || getUserAvatarURL(userInfo.id, userInfo.type)
          }
          fill
          alt="category"
          className="object-cover"
        />
      </div>
      <div
        className={cn('group-hover:line-clamp-1 max-w-205 text-left', {
          'text-16': isMedium,
        })}>
        {userInfo.displayName}
      </div>
      {userInfo.isVerified && (
        <div
          className="relative"
          onMouseOver={(e) => {
            e.preventDefault()
            setIsOpen(true)
          }}
          onClick={(e) => {
            e.preventDefault()
            setIsOpen(true)
          }}
          onMouseLeave={() => setIsOpen(false)}>
          <Verified fill={userInfo.type === 'user' ? '#23AC8A' : '#3366B6'} />
          <Tooltip
            isOpen={isOpen}
            noCloseBtn={true}
            className={cn(
              '-left-70 -top-40 max-lg:-top-46 opacity-0 transition-all z-[99]',
              { 'opacity-100': isOpen },
              { '-left-50': userInfo.type === 'user' },
            )}
            arrowClassName={cn('left-72 max-lg:left-72 -bottom-3 z-[-1]', {
              '!left-52': userInfo.type === 'user',
            })}
            text={
              <div className="c-b4 flex items-center gap-4">
                <CheckAll className="size-16 fill-[#A3A3A3]" />
                {userInfo.type === 'user' ? '인증된 기업' : '인증된 크리에이터'}
              </div>
            }
          />
        </div>
      )}

      {userInfo.isAgency && (
        <div className="relative">
          <AgencyVerified
            onMouseOver={(e) => {
              e.preventDefault()
              setIsOpen(true)
            }}
            onClick={(e) => {
              e.preventDefault()
              setIsOpen(true)
            }}
            onMouseLeave={() => setIsOpen(false)}
          />

          <Tooltip
            isOpen={isOpen}
            noCloseBtn={true}
            className={cn(
              'max-sm:-top-90 max-sm:-left-90 -left-130 -top-64 opacity-0 transition-all  ',
              {
                'opacity-100': isOpen,
              },
            )}
            arrowClassName={'max-sm:left-120 left-160 -bottom-3 z-[-1]'}
            text={
              <div className="c-b4 text-center">
                <span className="c-h8">Verified</span> 캐릭터는 IP 홀더의 요청에
                의해 <br />
                이너부스에서 직접 안전하게 <br className="sm:hidden" />
                중개를 도와드리고 있습니다.
              </div>
            }
          />
        </div>
      )}
      {userInfo.isAgency && (
        <Image
          src={getCountryImageURL(userInfo.country)}
          className={cn('absolute bottom-0 z-[11] left-16', {
            'left-26 bottom-8': isMedium,
          })}
          alt={`${userInfo.country}`}
          width={20}
          height={20}
        />
      )}
    </Link>
  )
}
