import { cn } from '@/app/utils'
import { useMediaQuery } from 'react-responsive'
import { twMerge } from 'tailwind-merge'
import Close from '../Icon/Close'

type tooltipType = {
  className?: string
  arrowClassName?: string
  text: string | React.ReactNode
  isOpen?: boolean
  close?: any
  noCloseBtn?: boolean
}

export default function Tooltip({
  className,
  arrowClassName,
  text,
  isOpen,
  close,
  noCloseBtn,
}: tooltipType) {
  const isMobile: boolean = useMediaQuery({
    query: '(max-width: 768px)',
  })
  return isMobile && isOpen ? (
    <div
      className={twMerge(
        'absolute bg-black rounded-md px-12 py-4  text-white leading-[22.4px] z-[1]',
        className,
      )}>
      <span className="flex items-center gap-x-4 whitespace-nowrap text-12">
        {text}
        {noCloseBtn === false && (
          <Close
            className="size-16 cursor-pointer fill-txt-secondary lg:hidden"
            onClick={() => close()}
          />
        )}
      </span>
      <div
        className={cn(
          'absolute size-14 rotate-[135deg] bg-black',
          arrowClassName,
        )}
      />
    </div>
  ) : (
    <div
      className={twMerge(
        'absolute bg-black rounded-md px-12 py-4  text-white leading-[22.4px] z-[1]',
        className,
      )}>
      <span className="flex items-center gap-x-4 whitespace-nowrap text-12">
        {text}
        {noCloseBtn === false && (
          <Close
            className="size-16 cursor-pointer fill-txt-secondary lg:hidden"
            onClick={() => close()}
          />
        )}
      </span>
      <div
        className={twMerge(
          'absolute size-14 rotate-[135deg] bg-black',
          arrowClassName,
        )}
      />
    </div>
  )
}
